import React, { useState } from "react";
import { ConfigProvider, Input, Table } from "antd";
import koKR from "antd/lib/locale/ko_KR";

export const MileageMemberInquiry = () => {
  // 테이블 데이터 예시
  const data = [
    {
      key: "1",
      name: "테스트",
      phone: "01075531234",
      mileage: 110000,
    },
    {
      key: "2",
      name: "손님",
      phone: "01066669999",
      mileage: 0,
    },
    {
      key: "3",
      name: "김지민",
      phone: "01075595719",
      mileage: 183300,
    },
    {
      key: "4",
      name: "손님",
      phone: "01063284580",
      mileage: 463900,
    },
    {
      key: "5",
      name: "손님",
      phone: "01012457890",
      mileage: 2100,
    },
    {
      key: "6",
      name: "손님",
      phone: "01088887458",
      mileage: 0,
    },
    {
      key: "7",
      name: "손님",
      phone: "01074748585",
      mileage: 0,
    },

    {
      key: "8",
      name: "테스트",
      phone: "01075531234",
      mileage: 110000,
    },
    {
      key: "9",
      name: "손님",
      phone: "01066669999",
      mileage: 0,
    },
    {
      key: "10",
      name: "김지민",
      phone: "01075595719",
      mileage: 183300,
    },
    {
      key: "11",
      name: "손님",
      phone: "01063284580",
      mileage: 463900,
    },
    {
      key: "12",
      name: "손님",
      phone: "01012457890",
      mileage: 2100,
    },
    {
      key: "13",
      name: "손님",
      phone: "01088887458",
      mileage: 0,
    },
    {
      key: "14",
      name: "손님",
      phone: "01074748585",
      mileage: 0,
    },
  ];

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // 이름(성명) 필터
  const filteredName = [...new Set(data.map((item) => item.name))].map(
    (name) => ({
      text: name,
      value: name,
    })
  );
  // 전화번호 필터
  const filteredPhone = [...new Set(data.map((item) => item.phone))].map(
    (phone) => ({
      text: phone,
      value: phone,
    })
  );
  // 마일리지 필터
  const filteredMileage = [...new Set(data.map((item) => item.mileage))].map(
    (mileage) => ({
      text: mileage,
      value: mileage,
    })
  );

  // 검색 input(입력폼)
  const handleSearch = (value) => {
    const filtered = data.filter(
      (item) => item.name.includes(value) || item.phone.includes(value)
    );
    setFilteredData(filtered);
    setSearchText(value);
  };
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  // 테이블 컬럼 생성
  const columns = [
    {
      title: "No.",
      render: (text, record, index) => index + 1,
      width: "10%",
      align: "center",
    },
    {
      title: "전화번호",
      dataIndex: "phone",
      key: "phone",
      filterSearch: true,
      filters: filteredPhone,
      filteredValue: filteredInfo.phone || null,
      onFilter: (value, record) => record.phone.includes(value),
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
      ellipsis: true,
      width: "40%",
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      filterSearch: true,
      filters: filteredName,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      width: "20%",
    },
    {
      title: "마일리지",
      dataIndex: "mileage",
      key: "mileage",
      filterSearch: true,
      filters: filteredMileage,
      filteredValue: filteredInfo.mileage || null,
      onFilter: (value, record) => record.mileage.toString().includes(value),
      sorter: (a, b) => a.mileage - b.mileage,
      sortOrder: sortedInfo.columnKey === "mileage" ? sortedInfo.order : null,
      ellipsis: true,
      width: "30%",
      align: "center",
    },
  ];

  return (
    <div className="p-16 pb-0">
      <h2>마일리지 회원 조회</h2>

      <ConfigProvider locale={koKR}>
        <Input
          placeholder="전화번호 또는 이름으로 검색하세요"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          className="flex-row mt-16 mb-8 ml-auto"
          style={{ width: "18.75rem" }}
        />

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={filteredData}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            scroll={{ y: "28.8rem" }}
            className="ant-table-respons"
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
