import React, { useEffect, useState } from "react";
import {
  roomReserveAllByAccomSearchApi,
  roomTypeAllByAccomSearchApi,
  roomAllByAccomSearchApi,
} from "../api/api";
import dayjs from "dayjs";
import { Input, Select, ConfigProvider, DatePicker, Table } from "antd";
import koKR from "antd/lib/locale/ko_KR";
import { disabledDate, disabledTime } from "../util";

export const ReserveMain = () => {
  const [searchText, setSearchText] = useState("");
  const [state, setState] = React.useState("");

  const [reveAllList, setReveAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomReserveAllByAccomSearchApi();
        const resArray = Object.entries(res.room_reserves);
        const reveAllList = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            agent: value.agent,
            internal_reserve_no: value.internal_reserve_no,
            stay_type: value.stay_type,
            state: value.state,
            check_in_sched: value.check_in_sched,
            check_out_sched: value.check_out_sched,
            phone: value.phone,
            room_type_id: value.room_type_id,
            room_id: value.room_id,
            ota_room_name: value.ota_room_name,
            name: value.name,
            fee: value.fee,
            prepaid: value.prepaid,
          };
        });
        //.sort((a, b) => a.no - b.no);
        console.log(reveAllList);
        setReveAllList(reveAllList);
        setFilteredData(reveAllList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // 룸정보 (API 연결)
  const [roomAllList, setRoomAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomAllByAccomSearchApi();
        const resArray = Object.entries(res.rooms);
        const roomAllArray = resArray
          .map(([key, value, no]) => {
            return {
              key: key,
              value: value,
              no: value.display_name,
              roomTypeId: value.room_type_id,
              roomSaleId: value.room_sale_id,
            };
          })
          .sort((a, b) => a.no - b.no);
        setRoomAllList(roomAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // 룸타입 정보 (API 연결)
  const [roomTypeAllList, setRoomTypeAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeAllByAccomSearchApi();
        const resArray = Object.entries(res.room_types);
        const roomTypeAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
          };
        });
        setRoomTypeAllList(roomTypeAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [filteredData, setFilteredData] = useState(reveAllList);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // 결제일자 필터
  const filteredCheckinDate = [
    ...new Set(
      reveAllList.map((item) => dayjs(item.check_in_sched).format("YYYY-MM-DD"))
    ),
  ].map((check_in_sched) => ({
    text: check_in_sched,
    value: check_in_sched,
  }));

  // 테이블 컬럼 생성
  const columns = [
    {
      title: "채널",
      dataIndex: "agent",
      key: "agent",
      width: "5%",
      align: "center",
    },
    {
      title: "OTA예약번호",
      dataIndex: "key",
      key: "key",
      width: "10%",
      align: "center",
    },
    {
      title: "예약번호",
      dataIndex: "internal_reserve_no",
      key: "internal_reserve_no",
      width: "5%",
      align: "center",
    },
    {
      title: "입실유형",
      dataIndex: "stay_type",
      key: "stay_type",
      width: "5%",
      align: "center",
      render: (text, record, index) => {
        if (text === "HOURS") {
          return <>대실</>;
        }
        if (text === "DAYS") {
          return <>숙박</>;
        }
        if (text === "LONG_DAYS") {
          return <>장기</>;
        }
        if (text === "OTHER") {
          return <>기타</>;
        }
        return null;
      },
    },
    {
      title: "상태",
      dataIndex: "state",
      key: "state",
      width: "5%",
      align: "center",
      render: (text, record, index) => {
        if (text === "GENERATED") {
          return <>자동예약</>;
        }
        if (text === "RESERVED") {
          return <>정상예약</>;
        }
        if (text === "USING") {
          return <>사용중</>;
        }
        if (text === "CANCLED") {
          return <>예약취소</>;
        }
        if (text === "USED") {
          return <>사용완료</>;
        }
        return null;
      },
    },
    {
      title: "입실시간",
      dataIndex: "check_in_sched",
      key: "check_in_sched",
      width: "10%",
      align: "center",
      render: (text, record, index) => {
        return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
      },

      filterSearch: true,
      filters: filteredCheckinDate,
      filteredValue: filteredInfo.check_in_sched || null,
      onFilter: (value, record) =>
        dayjs(record.check_in_sched).format("YYYY-MM-DD") === value,
      sorter: (a, b) => a.check_in_sched.localeCompare(b.check_in_sched),
      sortOrder:
        sortedInfo.columnKey === "check_in_sched" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "퇴실시간",
      dataIndex: "check_out_sched",
      key: "check_out_sched",
      width: "10%",
      align: "center",
      render: (text, record, index) => {
        return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "휴대폰",
      dataIndex: "phone",
      key: "phone",
      width: "10%",
      align: "center",
    },
    {
      title: "객실명",
      dataIndex: "room_id",
      key: "room_id",
      width: "5%",
      align: "center",
      render: (text, record, index) => {
        return (
          roomAllList?.find((room) => room.key === text)?.value.display_name ||
          "미배정"
        );
      },
    },
    {
      title: "객실유형",
      dataIndex: "room_type_id",
      key: "room_type_id",
      width: "5%",
      align: "center",
      render: (text, record, index) => {
        return (
          roomTypeAllList?.find((roomType) => roomType.key === text)?.value
            .display_name || ""
        );
      },
    },
    {
      title: "상품유형(OTA)",
      dataIndex: "ota_room_name",
      key: "ota_room_name",
      width: "10%",
      align: "center",
    },
    {
      title: "예약자",
      dataIndex: "name",
      key: "name",
      width: "10%",
      align: "center",
    },
    {
      title: "요금미수",
      dataIndex: "fee",
      key: "fee",
      width: "5%",
      align: "center",
      render: (text, record, index) => {
        return text - record.prepaid;
      },
    },
  ];

  // 달력
  const onChangeDatePicker = (date, dateString) => {
    console.log(dateString);
  };

  // 날짜별 정렬 버튼
  const setCheckinDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "check_in_sched",
    });
  };
  const handleSelectChange = (value) => {
    console.log(value);
  };

  // 검색 input(입력폼)
  const handleSearch = (value) => {
    const filtered = reveAllList.filter(
      (item) =>
        item.key.includes(value) ||
        item.internal_reserve_no.includes(value) ||
        item.name.includes(value)
    );
    setFilteredData(filtered);
    setSearchText(value);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  return (
    <div className="p-16 pb-0">
      <h2>예약 관리</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <DatePicker
            showTime
            onChange={onChangeDatePicker}
            defaultValue={dayjs()}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          />
          {/**
        <div className="btn-group ml-auto">
          <Button onClick={setPaymentDate}>날짜별 정렬</Button>
          <Button onClick={clearFilters}>전체 필터초기화</Button>
          <Button onClick={clearAll}>모두 초기화</Button>
        </div>
          */}
          <Select
            placeholder="예약상태"
            style={{ width: "15%", textAlign: "left" }}
            onChange={(value) => handleSelectChange(value)}
          >
            <Select.Option key="" value="전체예약">
              전체예약
            </Select.Option>
            <Select.Option key="RESERVED" value="정상예약">
              정상예약
            </Select.Option>
            <Select.Option key="USING" value="취소예약">
              취소예약
            </Select.Option>
            <Select.Option key="1" value="시간초과">
              시간초과
            </Select.Option>
            <Select.Option key="USING" value="취소예약">
              사용중
            </Select.Option>
            <Select.Option key="USING" value="취소예약">
              사용완료
            </Select.Option>
          </Select>

          <Input
            placeholder="예약번호 또는 예약자명으로 검색하세요"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="ml-auto"
            style={{ width: "18.75rem" }}
          />
        </div>

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            //dataSource={reveAllList}
            dataSource={filteredData}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            scroll={{
              y: "28.8rem",
              x: "2400px",
            }}
            className="ant-table-respons"
          />
        </div>
      </ConfigProvider>
    </div>
  );
  //<h1>예약메뉴의 메인페이지 입니다.</h1>;
};
