import React, { Children, useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  roomAllByAccomSearchApi,
  roomTypeAllByAccomSearchApi,
  doorLockAllByAccomSearchApi,
} from "../api/api";
import RoomTypeTabCont from "../commonComp/RoomTypeTabCont";

export const SettingRateTime = ({}) => {
  // 룸타입 정보 (API 연결)
  const [roomTypeAllList, setRoomTypeAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeAllByAccomSearchApi();
        const resArray = Object.entries(res.room_types);
        const roomTypeAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            // label: value.display_name,
            // description: value.display_name,
            // children: <RoomTypeTabCont description={value.display_name} />,
          };
        });
        setRoomTypeAllList(roomTypeAllArray);
        // console.log("roomTypeAllArray : ", roomTypeAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // Tab
  const onChangeTab = (key) => {
    console.log(key);
  };
  return (
    <div className="pt-16 plr-24 pb-24">
      <h2 className="mb-16">요금 및 시간 설정</h2>
      <Tabs
        type="card"
        className="setting-card-tab w-100"
        defaultActiveKey={
          roomTypeAllList.length > 0 ? roomTypeAllList[0].key : "1"
        }
        items={roomTypeAllList
          ?.map((roomType) => {
            console.log("roomType ==> ", roomType);
            return {
              key: roomType.key,
              label: roomType.value.display_name,
              children: (
                <RoomTypeTabCont description={roomType.value.display_name} />
              ),
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))}
        onChange={onChangeTab}
      />
    </div>
  );
};
