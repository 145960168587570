import React, { useState } from "react";
import { Button, Card, Checkbox, Input, List, Select } from "antd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

export const SettingReservation = ({ roomTypeAllList }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabled2, setIsEnabled2] = useState(false);
  const [isEnabled3, setIsEnabled3] = useState(false);
  const [isEnabled4, setIsEnabled4] = useState(false);
  const [isEnabled5, setIsEnabled5] = useState(false);
  const [isEnabled6, setIsEnabled6] = useState(false);
  const [isEnabled7, setIsEnabled7] = useState(false);
  const [isEnabled8, setIsEnabled8] = useState(true);

  const handleCheckboxChange = () => {
    setIsEnabled((prev) => !prev);
  };
  const handleCheckboxChange2 = () => {
    setIsEnabled2((prev) => !prev);
  };
  const handleCheckboxChange3 = () => {
    setIsEnabled3((prev) => !prev);
  };
  const handleCheckboxChange4 = () => {
    setIsEnabled4((prev) => !prev);
  };
  const handleCheckboxChange5 = () => {
    setIsEnabled5((prev) => !prev);
  };
  const handleCheckboxChange6 = () => {
    setIsEnabled6((prev) => !prev);
  };
  const handleCheckboxChange7 = () => {
    setIsEnabled7((prev) => !prev);
  };
  const handleCheckboxChange8 = () => {
    setIsEnabled8((prev) => !prev);
  };

  // 동적 입력 행추가 및 삭제
  // Input
  const [inputRows, setInputRows] = useState([]);

  const addInput = () => {
    setInputRows([
      ...inputRows,
      { id: Date.now(), value: "", selectValue: "" },
    ]);
  };

  const removeInput = () => {
    if (inputRows.length > 0) {
      setInputRows(inputRows.slice(0, -1));
    }
  };

  const handleChange = (id, value) => {
    setInputRows(
      inputRows.map((input) => (input.id === id ? { ...input, value } : input))
    );
  };

  // Select
  const handleSelectChange = (id, selectValue) => {
    setInputRows(
      inputRows.map((row) => (row.id === id ? { ...row, selectValue } : row))
    );
  };

  const onSearchSelect1 = (value) => {
    console.log("search:", value);
  };

  // 순위 설정
  const [items, setItems] = useState(roomTypeAllList);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
  // console.log("items : ", items);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  const handleDragDropToggle = () => {
    setIsDraggingEnabled(!isDraggingEnabled);
  };
  const handleSave = () => {
    console.log("저장된 순서:", items);
  };

  return (
    <div className="pt-16 plr-24 pb-24">
      <h2 className="mb-16">예약 연동 설정</h2>

      <div className="grid-col-2">
        <Card
          title={<h3 className="text-l">야놀자 모텔 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange} checked={isEnabled}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled}
              >
                -
              </Button>
            </div>
            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">야놀자 호텔 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange2} checked={isEnabled2}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled2} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled2} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled2} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled2}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled2}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled2}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled2}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">여기어때 마케팅센터 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange3} checked={isEnabled3}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled3} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled3} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled3} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled3}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled3}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled3}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled3}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">여기어때 파트너센터 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange4} checked={isEnabled4}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled4} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled4} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled4} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled4}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled4}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled4}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled4}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">네이버 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange5} checked={isEnabled5}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled5} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled5} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled5} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled5}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled5}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled5}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled5}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">에어비엔비 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange6} checked={isEnabled6}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled6} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled6} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled6} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled6}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled6}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled6}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled6}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">떠나요 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange7} checked={isEnabled7}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled7} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled7} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled7} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled7}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled7}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled7}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled7}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">아고다 연동 설정</h3>}
          className="setting-card narrow"
        >
          <div className="flex-col gap-8 text-l">
            <div className="ml-auto mb-8">
              <Checkbox onChange={handleCheckboxChange8} checked={isEnabled8}>
                사용여부
              </Checkbox>
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 ID</h4>
              <Input className="w-60" disabled={!isEnabled8} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>로그인 PW</h4>
              <Input className="w-60" disabled={!isEnabled8} />
            </div>
            <div className="flex-row gap-4 flex-wrap">
              <h4 style={{ width: "5rem" }}>업소명</h4>
              <Input className="w-60" disabled={!isEnabled8} />
            </div>

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>객실 유형 매핑</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                onClick={addInput}
                disabled={!isEnabled8}
              >
                +
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={removeInput}
                disabled={inputRows.length <= 0 || !isEnabled8}
              >
                -
              </Button>
            </div>

            {inputRows.map((input) => {
              return (
                <div key={input.id} className="flex-row gap-8">
                  <Input
                    value={input.value}
                    onChange={(e) => handleChange(input.id, e.target.value)}
                    placeholder="입력하세요"
                    className="w-50"
                    disabled={!isEnabled8}
                  />
                  <Select
                    showSearch
                    placeholder="객실유형을 선택하세요"
                    optionFilterProp="children"
                    className="w-50"
                    disabled={!isEnabled8}
                    onChange={(value) => handleSelectChange(input.id, value)}
                    // defaultValue={input.selectValue}
                    onSearch={onSearchSelect1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option.children.includes(input)
                    }
                  >
                    {roomTypeAllList
                      .sort((a, b) =>
                        b.value.display_name.localeCompare(a.value.display_name)
                      )
                      .map((roomType) => (
                        <Select.Option
                          key={roomType.key}
                          value={roomType.value.display_name}
                        >
                          {roomType.value.display_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              );
            })}

            <div className="flex-row gap-4 flex-wrap mt-16">
              <h4>랜덤 (특가) 상품 키오스크 체크인 배정 우선 순위</h4>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                icon={
                  isDraggingEnabled ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )
                }
                onClick={handleDragDropToggle}
              />
              {isDraggingEnabled && (
                <>
                  <h5 className="text-gray-600">
                    순위 변경은 드래그로 자유롭게 이동가능합니다.
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="dragNdrop-wrap"
                        >
                          <List
                            dataSource={items}
                            renderItem={(item, index) => (
                              <Draggable
                                key={item.key}
                                draggableId={item.key}
                                index={index}
                              >
                                {(provided) => (
                                  <List.Item
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    [{index + 1}순위]&nbsp;&nbsp;
                                    {item.value.display_name}
                                  </List.Item>
                                )}
                              </Draggable>
                            )}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Button
                    type="primary"
                    onClick={handleSave}
                    className="mt-8 ml-auto"
                  >
                    저장
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
