import { Card, Radio, Checkbox, InputNumber } from "antd";
import React, { useState } from "react";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
} from "../util";

export const SettingOperateRule = () => {
  const [value1, setValue1] = useState(1);
  const [value2, setValue2] = useState(1);
  const [value3, setValue3] = useState(1);
  const [value4, setValue4] = useState(3);
  const [value5, setValue5] = useState(2);

  const onChangeRadio1 = (e) => {
    console.log("radio-1 checked", e.target.value);
    setValue1(e.target.value);
  };
  const onChangeRadio2 = (e) => {
    console.log("radio-2 checked", e.target.value);
    setValue2(e.target.value);
  };
  const onChangeRadio3 = (e) => {
    console.log("radio-3 checked", e.target.value);
    setValue3(e.target.value);
  };
  const onChangeRadio4 = (e) => {
    console.log("radio-4 checked", e.target.value);
    setValue4(e.target.value);
  };
  const onChangeRadio5 = (e) => {
    console.log("radio-5 checked", e.target.value);
    setValue5(e.target.value);
  };

  const [checkedValues1, setCheckedValues1] = useState([1]);
  const [checkedValues2, setCheckedValues2] = useState([]);
  const [checkedValues3, setCheckedValues3] = useState([1, 2]);
  const [checkedValues4, setCheckedValues4] = useState([]);

  const onChangeCheckbox1 = (checkedValues1) => {
    console.log(`checked-1 = ${checkedValues1}`);
    setCheckedValues1(checkedValues1);
  };
  const onChangeCheckbox2 = (checkedValues2) => {
    console.log(`checked-2 = ${checkedValues2}`);
    setCheckedValues2(checkedValues2);
  };
  const onChangeCheckbox3 = (checkedValues3) => {
    console.log(`checked-3 = ${checkedValues3}`);
    setCheckedValues3(checkedValues3);
  };
  const onChangeCheckbox4 = (checkedValues4) => {
    console.log(`checked-4 = ${checkedValues4}`);
    setCheckedValues4(checkedValues4);
  };

  const onChangeInputNumber = (value) => {
    console.log("changed", value);
  };

  return (
    <div className="pt-16 plr-24 pb-24">
      <h2 className="mb-16">운영 규칙 설정</h2>

      <div className="grid-col-2">
        <Card
          title={<h3 className="text-l">고객키 제거시 운영 규칙</h3>}
          actions={[
            <Checkbox.Group onChange={onChangeCheckbox1} value={checkedValues1}>
              <Checkbox value={1}>
                퇴실 예정 시간을 초과한 경우에만 퇴실 처리 (그 외는 외출처리)
              </Checkbox>
            </Checkbox.Group>,
          ]}
          className="setting-card"
        >
          <div className="flex-col gap-4 text-l">
            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-16">대실 입실중인 경우</h4>
              <Radio.Group onChange={onChangeRadio1} value={value1}>
                <Radio value={1}>외출</Radio>
                <Radio value={2}>퇴실</Radio>
              </Radio.Group>
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-16">장기 입실중인 경우</h4>
              <Radio.Group onChange={onChangeRadio2} value={value2}>
                <Radio value={1}>외출</Radio>
                <Radio value={2}>퇴실</Radio>
              </Radio.Group>
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-16">숙박 입실중인 경우</h4>
              <Radio.Group onChange={onChangeRadio3} value={value3}>
                <Radio value={1}>외출</Radio>
                <Radio value={2}>퇴실</Radio>
              </Radio.Group>
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">고객키 삽입시 운영 규칙</h3>}
          className="setting-card"
        >
          <Checkbox.Group
            onChange={onChangeCheckbox2}
            value={checkedValues2}
            className="flex-col gap-8"
          >
            <Checkbox value={1}>대실 시간일 경우 자동 대실</Checkbox>
            <Checkbox value={2}>숙박 시간일 경우 자동 숙박</Checkbox>
          </Checkbox.Group>
        </Card>

        <Card
          title={<h3 className="text-l">퇴실시 운영 규칙</h3>}
          className="setting-card"
        >
          <Checkbox.Group
            onChange={onChangeCheckbox3}
            value={checkedValues3}
            className="flex-col gap-8"
          >
            <Checkbox value={1}>퇴실시 청소 요청</Checkbox>
            <Checkbox value={2}>퇴실시 객실 전원 차단</Checkbox>
          </Checkbox.Group>
        </Card>

        <Card
          title={<h3 className="text-l">청소키 제거시 운영 규칙</h3>}
          className="setting-card"
        >
          <Radio.Group onChange={onChangeRadio4} value={value4}>
            <Radio value={1}>사용안함</Radio>
            <Radio value={2}>항상 점검대기</Radio>
            <Radio value={3}>퇴실 상태에서만 점검대기</Radio>
            <Radio value={4}>항상 공실처리</Radio>
          </Radio.Group>
        </Card>

        <Card
          title={<h3 className="text-l">매출 정산 규칙</h3>}
          className="setting-card"
        >
          <div className="flex-col gap-10 text-l">
            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">일일 매출 마감 시간</h4>
              <InputNumber
                min={1}
                max={9999}
                defaultValue={10}
                formatter={formatNumber}
                onChange={onChangeInputNumber}
                onKeyDown={handleInputNumberKeyDown}
                onInput={handleInputNumberOnInput}
              />
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">매출 저장 일 수</h4>
              <InputNumber
                min={1}
                max={9999}
                defaultValue={1830}
                formatter={formatNumber}
                onChange={onChangeInputNumber}
                onKeyDown={handleInputNumberKeyDown}
                onInput={handleInputNumberOnInput}
              />
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">기본 결제 방법</h4>
              <Radio.Group onChange={onChangeRadio5} value={value5}>
                <Radio value={1}>사용안함</Radio>
                <Radio value={2}>현금결제</Radio>
                <Radio value={3}>카드결제</Radio>
                <Radio value={4}>OTA결제</Radio>
              </Radio.Group>
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">키오스크 예약 입실 가능 시간</h3>}
          className="setting-card"
        >
          <h4 className="mb-8">체크인 시간</h4>
          <div className="flex-row gap-8 flex-wrap">
            <InputNumber
              min={0}
              max={24}
              defaultValue={0}
              onChange={onChangeInputNumber}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
            />
            시간
            <InputNumber
              min={0}
              max={59}
              defaultValue={35}
              onChange={onChangeInputNumber}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
            />
            분 전부터 입실 가능
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">객실관리 옵션</h3>}
          className="setting-card"
        >
          <Checkbox.Group
            onChange={onChangeCheckbox4}
            value={checkedValues4}
            className="flex-col gap-8"
          >
            <Checkbox value={1}>
              CHB LED 외출 구분 모드 (씨리얼, 아이크루 객실관리 전용)
            </Checkbox>
            <Checkbox value={2}>비상호출 비활성화</Checkbox>
            <Checkbox value={3}>차량호출 비활성화</Checkbox>
          </Checkbox.Group>
        </Card>

        <Card
          title={<h3 className="text-l">마일리지</h3>}
          className="setting-card"
        >
          <div className="flex-col gap-10 text-l">
            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">현금&nbsp; 적립율</h4>
              <InputNumber
                min={0}
                max={100}
                defaultValue={0}
                formatter={formatNumber}
                onChange={onChangeInputNumber}
                onKeyDown={handleInputNumberKeyDown}
                onInput={handleInputNumberOnInput}
              />
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">카드&nbsp; 적립율</h4>
              <InputNumber
                min={0}
                max={100}
                defaultValue={0}
                formatter={formatNumber}
                onChange={onChangeInputNumber}
                onKeyDown={handleInputNumberKeyDown}
                onInput={handleInputNumberOnInput}
              />
            </div>

            <div className="flex-row gap-8 flex-wrap">
              <h4 className="mr-8">OTA 적립율</h4>
              <InputNumber
                min={0}
                max={100}
                defaultValue={0}
                formatter={formatNumber}
                onChange={onChangeInputNumber}
                onKeyDown={handleInputNumberKeyDown}
                onInput={handleInputNumberOnInput}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
