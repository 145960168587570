import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";

export const ModalTab1 = ({
  rooms,
  roomTypeAllList,
  roomReserveAllList,
  TimeFormatter,
  roomSaleAllList,
}) => {
  // console.log("rooms ===> ", rooms);

  const onChangeInputNumber1 = (value) => {
    console.log("투숙인원", value);
  };
  const onChangeInputNumber2 = (value) => {
    console.log("객실요금", value);
  };
  const onChangeInputNumber3 = (value) => {
    console.log("선결제액", value);
  };

  // 달력
  const dateFormat = "YYYY-MM-DD";
  const onChangeDatePicker = (date, dateString) => {
    console.log(date, dateString);
  };

  // select
  const onChangeSelect1 = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearchSelect1 = (value) => {
    console.log("search:", value);
  };

  // checkbox
  const onChangeCheckBox1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  // switch
  const onChangeSwitch1 = (checked) => {
    console.log(`switch to ${checked}`);
  };

  // radio
  const onChangeRadio1 = (e) => {
    console.log(`투숙형태:${e.target.value}`);
  };

  // 시간 변환 : ex) 2024년 09월 30일 월요일 15시 00분
  const DateTimeFormatter = ({ dateTime }) => {
    const date = new Date(Number(dateTime));
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat("ko-KR", options).format(
      date
    );

    const part1 = formattedDate.split(". "); // 날짜 분리
    const part2 = part1[3].split(" ").slice(0); // 요일,시간 분리
    const weekDay = part2[0];
    const [time] = part1[3].split(" ").slice(-1);
    const [hour, minute] = time.split(":");

    return `${part1[0]}년 ${part1[1]}월 ${part1[2]}일 ${weekDay} ${hour}시 ${minute}분`;
  };

  // 시간 변환 : ex) 09-30 15:00
  const DateTimeFormatterSimple = ({ dateTime }) => {
    const date = new Date(Number(dateTime));
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat("ko-KR", options).format(
      date
    );

    const part1 = formattedDate.split(". "); // 날짜 분리
    const [time] = part1[3].split(" ").slice(-1);
    const [hour, minute] = time.split(":");

    return `${part1[1]}-${part1[2]} ${hour}:${minute}`;
  };

  // 예약 - selectBox
  const [selectedRes, setSelectedRes] = useState(null);
  const handleChange2 = (value) => {
    setSelectedRes(value);
    console.log(`selected ${value}`);
  };

  {
    roomReserveAllList?.map((roomRes) => {
      console.log(roomRes);
      // console.log(roomRes.key === "pgXtsIrGZ5Td9AsYyPQV" ? roomRes.value : "");
    });
  }

  {
    roomSaleAllList?.map((roomSale) => {
      // console.log(roomSale);
      // console.log(
      //   roomSale.key === "Pq6TwLoGaQjs70QISL9W" ? roomSale.value : ""
      // );
    });
  }

  // console.log(rooms);

  return (
    <div className="flex-col">
      {/* 객실명 */}
      <div className="flex-row flex-wrap gap-10 mb-16">
        <h3 className="label p-0">객실명 &nbsp;:</h3>
        <h2>{rooms.value.display_name}호 &nbsp;-</h2>
        {roomTypeAllList?.map((roomType, idx) => {
          if (roomType.key === rooms.value.room_type_id) {
            return (
              <h2 key={`roomType - ${idx}`} className="mr-16">
                {roomType.value.display_name}
              </h2>
            );
          }
          return null;
        })}
        <div className="w-full flex-1">
          {/* <div>room_id : {rooms.key}</div>
          <div>room_type_id : {rooms.value.room_type_id}</div>
          <div>room_sale_id : {rooms.value.room_sale_id}</div>
          <div>room_reserve_id : {rooms.value.room_reserve_id}</div> */}
          <Select
            style={{ width: "100%", minWidth: "17.5rem" }}
            onChange={handleChange2}
            value={selectedRes}
          >
            {/* <Select.Option value={null}>없음</Select.Option> */}
            {roomReserveAllList.map((roomRes, idx) =>
              roomRes.value.room_type_id === rooms.value.room_type_id ? (
                <Select.Option key={`roomRes-${idx}`}>
                  <div className="flex-row gap-8">
                    <div>
                      [
                      {roomRes.value.agent === "YANOLJA"
                        ? "야놀자(M)"
                        : roomRes.value.agent === "YANOLJA_HOTEL"
                        ? "야놀자(H)"
                        : roomRes.value.agent === "GOOD_CHOICE"
                        ? "여기어때(M)"
                        : roomRes.value.agent === "GOOD_CHOICE_HOTEL"
                        ? "여기어때(H)"
                        : roomRes.value.agent === "NAVER"
                        ? "네이버"
                        : roomRes.value.agent === "AIR_BNB"
                        ? "에어비엔비"
                        : roomRes.value.agent === "AGODA"
                        ? "아고다"
                        : roomRes.value.agent === "EXPEDIA"
                        ? "익스피디아"
                        : roomRes.value.agent === "ONDA"
                        ? "온다"
                        : roomRes.value.agent === "BOOKING_HOLDINGS"
                        ? "부킹닷컴"
                        : roomRes.value.agent === "KULL_STAY"
                        ? "꿀스테이"
                        : roomRes.value.agent === "DDNAYO"
                        ? "떠나요"
                        : roomRes.value.agent === "FRONT"
                        ? "프론트"
                        : roomRes.value.agent === "OTHER"
                        ? "기타"
                        : "OTHER"}
                      ]
                    </div>
                    <DateTimeFormatterSimple
                      dateTime={roomRes.value.check_in_sched}
                    />
                    <div>
                      {roomRes.value.stay_type === "HOURS"
                        ? "대실"
                        : roomRes.value.stay_type === "DAYS"
                        ? "숙박"
                        : roomRes.value.stay_type === "LONG_DAYS"
                        ? "징기"
                        : roomRes.value.stay_type === "OTHER"
                        ? "기타"
                        : null}
                    </div>
                    <div>{roomRes.value.name}</div>
                    <div>{roomRes.value.phone}</div>
                  </div>
                </Select.Option>
              ) : null
            )}
          </Select>
        </div>
      </div>

      {/* 투숙형태 */}
      <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
        <div className="flex-row">
          <h3 className="label">투숙형태 &nbsp;:</h3>
          <div className="btn-group text-l">
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              className="room-stay-type"
              defaultValue={rooms.value.stay_type}
              onChange={onChangeRadio1}
            >
              <Radio value={"HOURS"} label={"HOURS"}>
                대실
              </Radio>
              <Radio value={"DAYS"} label={"DAYS"}>
                숙박
              </Radio>
              <Radio value={"LONG_DAYS"} label={"LONG_DAYS"}>
                장기
              </Radio>
              <Radio value={"OTHER"} label={"OTHER"}>
                기타
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <div className="flex-row">
          <h3 className="label">투숙인원 &nbsp;:</h3>
          {rooms.value.room_sale_id ? (
            roomSaleAllList?.map((roomSale, idx) => {
              if (rooms.value.room_sale_id === roomSale.key) {
                return (
                  <InputNumber
                    min={1}
                    max={100}
                    defaultValue={roomSale.value.person_count}
                    key={`roomSale-person-count${idx}`}
                    onChange={onChangeInputNumber1}
                  />
                );
              }
            })
          ) : (
            <>
              {/* 값이 없을때 */}
              <InputNumber
                min={1}
                max={100}
                defaultValue={2}
                onChange={onChangeInputNumber1}
              />
            </>
          )}
        </div>
      </div>

      {/* 입실시간 */}
      <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
        <div className="flex-row">
          <h3 className="label">입실시간 &nbsp;:</h3>
          {rooms.value.last_check_in_time === null ? (
            <DateTimeFormatter dateTime={new Date()} />
          ) : (
            <DateTimeFormatter dateTime={rooms.value.last_check_in_time} />
          )}
        </div>
        {roomReserveAllList?.map((roomRes, idx) => {
          if (roomRes.key === rooms.value.room_reserve_id) {
            return (
              <div className="flex-row" key={`roomRes-tel-${idx}`}>
                <h3 className="label">전화번호 &nbsp;:</h3>
                <Input placeholder="-없이" value={roomRes.value.phone} />
              </div>
            );
          }
        })}
      </div>

      {/* 퇴실예정 */}
      <div className="flex-row sec-2 mb-16">
        <div className="flex-row">
          <h3 className="label">퇴실예정 &nbsp;:</h3>
          {roomReserveAllList?.map((roomRes, idx) => {
            if (roomRes.key === rooms.value.room_reserve_id) {
              return (
                <DateTimeFormatter
                  dateTime={roomRes.value.check_out_sched}
                  key={`roomRes-checkout-time-${idx}`}
                />
              );
            }
          })}
          {rooms.value.room_reserve_id === null ? (
            <DateTimeFormatter dateTime={new Date()} />
          ) : null}
        </div>
        {roomReserveAllList?.map((roomRes, idx) => {
          if (roomRes.key === rooms.value.room_reserve_id) {
            return (
              <div className="flex-row" key={`roomRes-car-${idx}`}>
                <h3 className="label">차량번호 &nbsp;:</h3>
                <Input placeholder="최대 8자" />
              </div>
            );
          }
        })}
      </div>

      {/* 객실요금 */}
      <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
        <div className="flex-row">
          <h3 className="label">객실요금 &nbsp;:</h3>
          {roomReserveAllList?.map((roomRes, idx) => {
            if (rooms.value.room_reserve_id === roomRes.key) {
              return (
                <InputNumber
                  min={1}
                  max={999999999999}
                  style={{ width: "100%" }}
                  defaultValue={roomRes.value.fee}
                  key={`roomRes-fee-${idx}`}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  onChange={onChangeInputNumber2}
                />
              );
            }
          })}
          {rooms.value.room_reserve_id === null ? (
            <InputNumber
              min={1}
              max={999999999999}
              style={{ width: "100%" }}
              defaultValue={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={onChangeInputNumber2}
            />
          ) : null}
        </div>
        <div className="flex-row">
          <div className="btn-group">
            <Button type="primary" htmlType="button">
              계좌추가
            </Button>
            <Button type="primary" htmlType="button">
              OTA추가
            </Button>
            <Button type="primary" htmlType="button">
              현금추가
            </Button>
            <Button type="primary" htmlType="button">
              카드추가
            </Button>
          </div>
        </div>
      </div>

      {/* 결제일자 */}
      <div className="flex-row sec-2 mb-16">
        <div className="flex-row flex-wrap gap-16">
          <div className="flex-row">
            <h3 className="label">결제일자 &nbsp;:</h3>
            <DatePicker
              onChange={onChangeDatePicker}
              // defaultValue={dayjs()}
              style={{ minWidth: "8.75rem" }}
              defaultValue={dayjs(rooms.value.last_check_in_time)}
            />
          </div>

          <div className="flex-row">
            <h3 className="label">선결제액 &nbsp;:</h3>
            {roomReserveAllList?.map((roomRes, idx) => {
              if (roomRes.key === rooms.value.room_reserve_id) {
                return (
                  <InputNumber
                    min={1}
                    max={999999999999}
                    style={{ width: "100px" }}
                    defaultValue={roomRes.value.prepaid}
                    key={`roomRes-prepaid-${idx}`}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    onChange={onChangeInputNumber3}
                  />
                );
              }
            })}
            {rooms.value.room_reserve_id === null ? (
              <InputNumber
                min={1}
                max={999999999999}
                style={{ width: "100px" }}
                defaultValue={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={onChangeInputNumber3}
              />
            ) : null}
          </div>

          <div className="flex-row gap-16">
            <h3 className="label p-0">대행사 &nbsp;:</h3>
            {roomSaleAllList?.map((roomSale, idx) => {
              if (rooms.roomSaleId === roomSale.key) {
                return (
                  <Select
                    showSearch
                    placeholder="선택하세요"
                    defaultValue={roomSale.value.payments[0].agent_type}
                    key={`roomSale-agent-type-${idx}`}
                    style={{ minWidth: "10rem" }}
                    optionFilterProp="label"
                    onChange={onChangeSelect1}
                    onSearch={onSearchSelect1}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placement={"topLeft"}
                    options={[
                      {
                        value: "FRONT",
                        label: "프론트",
                      },
                      {
                        value: "YANOLJA",
                        label: "야놀자(모텔)",
                      },
                      {
                        value: "YANOLJA_HOTEL",
                        label: "야놀자(호텔)",
                      },
                      {
                        value: "GOOD_CHOICE",
                        label: "여기어때(모텔)",
                      },
                      {
                        value: "GOOD_CHOICE_HOTEL",
                        label: "여기어때(호텔)",
                      },
                      {
                        value: "NAVER",
                        label: "네이버",
                      },
                      {
                        value: "AIR_BNB",
                        label: "에어비엔비",
                      },
                      {
                        value: "AGODA",
                        label: "아고다",
                      },
                      {
                        value: "EXPEDIA",
                        label: "익스피디아",
                      },
                      {
                        value: "ONDA",
                        label: "온다",
                      },
                      {
                        value: "BOOKING_HOLDINGS",
                        label: "부킹닷컴",
                      },
                      {
                        value: "KULL_STAY",
                        label: "꿀스테이",
                      },
                      {
                        value: "DDNAYO",
                        label: "떠나요",
                      },
                      {
                        value: "OTHER",
                        label: "기타",
                      },
                    ]}
                  />
                );
              }
            })}

            {rooms.value.room_reserve_id === null ? (
              <Select
                showSearch
                placeholder="선택하세요"
                defaultValue={"OTHER"}
                style={{ minWidth: "10rem" }}
                optionFilterProp="label"
                onChange={onChangeSelect1}
                onSearch={onSearchSelect1}
                getPopupContainer={(trigger) => trigger.parentNode}
                placement={"topLeft"}
                options={[
                  {
                    value: "FRONT",
                    label: "프론트",
                  },
                  {
                    value: "YANOLJA",
                    label: "야놀자(모텔)",
                  },
                  {
                    value: "YANOLJA_HOTEL",
                    label: "야놀자(호텔)",
                  },
                  {
                    value: "GOOD_CHOICE",
                    label: "여기어때(모텔)",
                  },
                  {
                    value: "GOOD_CHOICE_HOTEL",
                    label: "여기어때(호텔)",
                  },
                  {
                    value: "NAVER",
                    label: "네이버",
                  },
                  {
                    value: "AIR_BNB",
                    label: "에어비엔비",
                  },
                  {
                    value: "AGODA",
                    label: "아고다",
                  },
                  {
                    value: "EXPEDIA",
                    label: "익스피디아",
                  },
                  {
                    value: "ONDA",
                    label: "온다",
                  },
                  {
                    value: "BOOKING_HOLDINGS",
                    label: "부킹닷컴",
                  },
                  {
                    value: "KULL_STAY",
                    label: "꿀스테이",
                  },
                  {
                    value: "DDNAYO",
                    label: "떠나요",
                  },
                  {
                    value: "OTHER",
                    label: "기타",
                  },
                ]}
              />
            ) : null}

            <Button type="primary" htmlType="button" className="ml-auto">
              삭제
            </Button>
          </div>
        </div>
      </div>

      {/* 고객메모 */}
      <div className="flex-row mb-16">
        <h3 className="label">고객메모 &nbsp;:</h3>
        <Input
          // value={roomRes.value.momo}
          placeholder="고객메모는 매출별 적용됩니다.(객실 표시는 상당 [상세정보] 탭의 [객실표시]란에 메모해 주세요.)"
        />
      </div>

      {/* 알림 */}
      <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
        <div className="flex-row">
          <Checkbox onChange={onChangeCheckBox1}>알림</Checkbox>
          <span>20시 58분에 고객 메모 팝업</span>
        </div>
        <div className="flex-row">
          <Switch onChange={onChangeSwitch1} /> &nbsp;&nbsp;
          <span>알림시 고객 메모 음성 재생</span>
        </div>
      </div>
    </div>
  );
};
