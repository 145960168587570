import dayjs from "dayjs";
import { useState } from "react";

// 달력 공통
export const disabledDate = (current) => {
  return current && current > dayjs().endOf("day");
};

export const disabledTime = (current) => {
  if (!current)
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };

  const currentHour = dayjs().hour();
  const currentMinute = dayjs().minute();
  const currentSecond = dayjs().second();

  return {
    disabledHours: () => {
      // 현재 시간 이후의 시간 비활성화
      const hours = [];
      for (let i = currentHour + 1; i < 24; i++) {
        hours.push(i);
      }
      return hours;
    },
    disabledMinutes: () => {
      if (current.hour() === currentHour) {
        // 현재 시간이면 현재 시간 이후의 분 비활성화
        const minutes = [];
        for (let i = currentMinute + 1; i < 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    },
    disabledSeconds: () => {
      if (
        current.hour() === currentHour &&
        current.minute() === currentMinute
      ) {
        // 현재 분이면 현재 분 이후의 초 비활성화
        const seconds = [];
        for (let i = currentSecond + 1; i < 60; i++) {
          seconds.push(i);
        }
        return seconds;
      }
      return [];
    },
  };
};

// InputNumber 다른 키 입력 방지
export const handleInputNumberKeyDown = (e) => {
  // 숫자 키 (0-9) 및 백스페이스, 탭, 엔터키만 허용
  if (
    !(
      (e.key >= "0" && e.key <= "9") ||
      e.key === "Backspace" ||
      e.key === "Tab" ||
      e.key === "Enter" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown"
    )
  ) {
    e.preventDefault();
  }
};

// InputNumber 한글 입력 방지
export const handleInputNumberOnInput = (e) => {
  if (/[\u3131-\u3163\uAC00-\uD7A3]/.test(e.target)) {
    e.preventDefault();
  }
};

// InputNumber 1000단위 콤마
export const formatNumber = (num) => {
  if (!num) return "";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
