import React, { useEffect, useState } from "react";
import {
  WifiOutlined,
  PoweroffOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, Image, Modal, Tag, Tooltip, message, Tabs } from "antd";
import stateIconClean from "../images/icon/broom-unstroke.png";
import stateIconCheckIn from "../images/icon/check-in.png";
import stateIconCheckOut from "../images/icon/check-out.png";
import stateIconBed from "../images/icon/bed.png";
import stateIconInspection from "../images/icon/inspection.png";
import airConditioner from "../images/icon/air-conditioner.png";
import { ModalTab1 } from "./ModalTab1";

export const Card = ({
  rooms,
  roomTypeId,
  roomSaleId,
  roomTypeAllList,
  roomReserveAllList,
  roomSaleAllList,
}) => {
  // 드롭다운
  const items = [
    {
      label: <div>{rooms.value.display_name}호</div>,
      key: 0,
    },
    {
      label: "대실",
      key: 1,
    },
    {
      label: "숙박",
      key: 2,
    },
    {
      label: "장기",
      key: 3,
    },
    {
      label: "청소 요청(청소 완료)",
      key: 4,
    },
    {
      label: "정검 요청",
      key: 5,
    },
    {
      label: "공실 처리",
      key: 6,
    },
    {
      label: "퇴실 취소",
      key: 7,
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();
  const onClick = ({ key }) => {
    messageApi.info(`Click on item ${key}`);
  };

  // 모달
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 시간 변환 : ex) (15:00)
  const TimeFormatter = ({ lastTime }) => {
    const date = new Date(Number(lastTime));
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
    const formattedTime = date
      .toLocaleTimeString([], timeOptions)
      .replace(/오전|오후/, "");
    return <>({formattedTime})</>;
  };

  // 탭
  const itemsTab = [
    {
      key: "1",
      label: "입실정보",
      children: (
        <ModalTab1
          rooms={rooms}
          roomTypeAllList={roomTypeAllList}
          roomReserveAllList={roomReserveAllList}
          TimeFormatter={TimeFormatter}
          roomSaleAllList={roomSaleAllList}
        />
      ),
    },
    {
      key: "2",
      label: "상태정보",
      children: "상태정보",
    },
    {
      key: "3",
      label: "매출조회",
      disabled: true,
      children: "매출조회",
    },
    {
      key: "4",
      label: "이력조회",
      children: "이력조회",
    },
  ];

  return (
    <>
      <div className="card-wrap">
        <div onClick={showModal}>
          {/* 상태표시 - 바 */}
          <div
            className={
              rooms.value.state_summary === "USING"
                ? "state-bar red"
                : rooms.value.state_summary === "CLEAN_ORDER"
                ? "state-bar green"
                : rooms.value.state_summary === "INSPECT_ORDER"
                ? "state-bar purple"
                : rooms.value.state_summary === "INSPECTING"
                ? "state-bar orange"
                : "state-bar"
            }
          ></div>

          {/* 헤더 - 방번호 및 타이틀 */}
          {/* <p>roomId : {rooms.key}</p> */}
          {/* <p>roomTypeId : {rooms.value.room_type_id}</p> */}

          <div className="room-card-tit">
            <h3>{rooms.value.display_name}호 -&nbsp;</h3>
            {roomTypeAllList?.map((roomType, idx) => {
              return (
                <h3 key={`roomType - ${idx}`} className="ellipsis">
                  {roomType.key === rooms.value.room_type_id
                    ? roomType.value.display_name
                    : ""}
                </h3>
              );
            })}
          </div>

          {/* 상태표시 - 아이콘 */}
          <div className="state-icons">
            <div className="state-btn">
              <Tooltip
                title={
                  <div>
                    객실 통신상태 입니다.
                    <br />
                    (적색: 통신정상, 회색: 통신단절)
                    <br />
                    통신 단절시, 일부 객실 상태와 제어가 동작하지 않을 수
                    있습니다.
                  </div>
                }
              >
                <Tag color={rooms.value.connection ? "#f50" : "#efefef"}>
                  <WifiOutlined />
                </Tag>
              </Tooltip>

              <Tooltip
                title={<div>키오스크 카드데크에 수납된 카드 개수 입니다.</div>}
              >
                <Tag color="green">
                  <h6>2</h6>
                </Tag>
              </Tooltip>

              <Tooltip
                title={
                  <div>
                    객실의 콘센트 전원입니다. <br />
                    (청색: On, 회색 : Off)
                  </div>
                }
              >
                <Tag color={rooms.value.main_power ? "#108ee9" : "#efefef"}>
                  <PoweroffOutlined />
                </Tag>
              </Tooltip>

              <Tooltip
                title={
                  <div>
                    객실의 에어컨 전원입니다.
                    <br />
                    (청색: On, 회색 : Off)
                    <br />
                    해당 상태는 에어컨의 전원 공급만을 의미하므로 에어컨 가동
                    여부와는 무관합니다.
                  </div>
                }
              >
                <Tag color={rooms.value.aircon_power ? "#108ee9" : "#efefef"}>
                  <img src={airConditioner} alt="에어컨 아이콘" width={22} />
                </Tag>
              </Tooltip>
            </div>

            {/* 상태표시 - 이미지 */}
            <div className="img-wrap">
              {rooms.value.clean_order ? (
                <div className="flex-col-center">
                  <img src={stateIconClean} alt="청소요청 아이콘" />
                  <span className="green">청소요청</span>
                </div>
              ) : rooms.value.inspect_order ? (
                <div className="flex-col-center">
                  <img src={stateIconInspection} alt="점검요청 아이콘" />
                  <span>점검대기</span>
                </div>
              ) : rooms.value.state_summary === "INSPECTING" ? (
                <div className="flex-col-center">
                  <img src={stateIconInspection} alt="점검중 아이콘" />
                  <span>점검중</span>
                </div>
              ) : rooms.value.state_summary === "USING" ? (
                <div className="flex-col-center">
                  <img src={stateIconCheckIn} alt="체크인 아이콘" />
                  <span className="red">사용중</span>
                </div>
              ) : (
                <img src={stateIconBed} alt="기본 아이콘" />
              )}
            </div>
          </div>
          {/* {rooms.value.state_summary} */}
        </div>

        {/* 푸터 */}
        <div className="footer">
          <div className="result">
            {rooms.value.state_summary === "CLEAN_ORDER"
              ? "청소"
              : rooms.value.state_summary === "USING"
              ? "입실"
              : rooms.value.state_summary === "EMPTY" ||
                rooms.value.state_summary === "INSPECT_ORDER" ||
                rooms.value.state_summary === "INSPECTING"
              ? "퇴실"
              : null}

            {rooms.value.state_summary === "EMPTY" ? (
              <TimeFormatter lastTime={rooms.value.last_check_out_time} />
            ) : rooms.value.state_summary === "USING" ? (
              <TimeFormatter lastTime={rooms.value.last_check_in_time} />
            ) : rooms.value.state_summary === "CLEAN_ORDER" ? (
              <TimeFormatter
                lastTime={rooms.value.last_clean_order_start_time}
              />
            ) : rooms.value.state_summary === "INSPECT_ORDER" ? (
              <TimeFormatter
                lastTime={rooms.value.last_inspect_order_start_time}
              />
            ) : rooms.value.state_summary === "INSPECTING" ? (
              <TimeFormatter lastTime={rooms.value.last_check_out_time} />
            ) : null}
          </div>
          {rooms.value.temp === 0 ? null : (
            <div className="font-12-600 text-red">{rooms.value.temp}℃</div>
          )}
          <div className="more">
            {contextHolder}
            <Dropdown
              menu={{ items, onClick }}
              trigger={["click"]}
              overlayClassName="cuzDrop1"
            >
              <MoreOutlined />
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal
        title={
          <div className="flex-row">
            <h3>{rooms.value.display_name}호 -&nbsp;</h3>
            {roomTypeAllList?.map((roomType, idx) => {
              return (
                <h3 key={`roomType - ${idx}`}>
                  {roomType.key === rooms.value.room_type_id
                    ? roomType.value.display_name
                    : ""}
                </h3>
              );
            })}
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        okText="저장"
        onCancel={handleCancel}
        cancelText="닫기"
        width={1000}
        maskClosable={false}
      >
        <Tabs defaultActiveKey="1" items={itemsTab} />
      </Modal>
    </>
  );
};
