import React, { useState } from "react";
import { Button, Card, Checkbox, InputNumber } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
} from "../util";

const RoomTypeTabCont = (props) => {
  console.log("props ===> ", props);

  const onChangeInputNumber = (value) => {
    console.log("changed", value);
  };

  // CheckBox
  const [checkedValues1, setCheckedValues1] = useState([1, 2]);
  const onChangeCheckbox1 = (checkedValues1) => {
    console.log(`checked-1 = ${checkedValues1}`);
    setCheckedValues1(checkedValues1);
  };

  return (
    <>
      {/* <h3 className="pb-8">* 룸 타입명 : {props.description}</h3> */}

      <div className="grid-col-12 pt-8">
        <Card
          title={<h3 className="text-l">기본 사용 시간 설정</h3>}
          className="setting-card narrow grid-span-8"
        >
          <div className="flex-col gap-4">
            <h4 className="text-purple">기본 요금 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              세부 요금설정을 하지 않은경우 적용되는 기본 요금 입니다.
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>대실 기본 요금</h4>
                <InputNumber
                  min={1}
                  max={9999999999}
                  defaultValue={15000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "10rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>숙박 기본 요금</h4>
                <InputNumber
                  min={1}
                  max={9999999999}
                  defaultValue={7000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "10rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">대실 이용 가능 시간 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              대실 이용 시간을 설정합니다. 퇴실시간이 대실 시간을 지날 경우,
              퇴실 시간은 대실 마감시간이 됩니다. <br />
              (예 : 대실 마감시간 - 18시, 체크인 시간 - 15시, 대실 - 4시간
              사용일 경우 퇴실 시간은 19시가 아닌 18시가 됩니다.)
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 마감 시간</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={0}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />{" "}
                ~
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={23}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 마감 시간</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={0}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />{" "}
                ~
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={23}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">대실 기본 이용 시간 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              기본 요금제의 대실 기본 사용시간을 설정합니다.
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오전</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={8}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오후</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={10}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오전</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={8}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오후</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={8}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">숙박 기본 퇴실 시간 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              기본 숙박 요금제의 퇴실 시간을 설정합니다. 주말 퇴실 시간은 금-토,
              토-일 숙박시 적용됩니다.
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 퇴실 시간</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={19}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 퇴실 시간</h4>
                <InputNumber
                  min={0}
                  max={23}
                  defaultValue={18}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "5rem" }}
                />
              </div>
            </div>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">기본 요금 설정</h3>}
          className="setting-card narrow grid-span-4"
        >
          <div className="flex-col gap-4">
            <h4 className="text-purple">객실 관리 요금 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              해당 요금 설정은 객실관리 프로그램(Eagle)에만 적용되며, 키오스크는
              적용되지 않습니다.
            </p>
            <Button type="primary" htmlType="button">
              설정하기
            </Button>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">키오스크 요금 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              해당 요금 설정은 키오스크에만 적용되며, 객실관리
              프로그램(Eagle)에는 적용되지 않습니다. 또한, 지정되지 않은
              시간대에선 객실을 판매하지 않습니다.
            </p>
            <Button type="primary" htmlType="button">
              설정하기
            </Button>
          </div>
        </Card>

        <Card
          title={<h3 className="text-l">추가 요금 설정</h3>}
          className="setting-card narrow grid-span-8"
        >
          <div className="flex-col gap-4">
            <h4 className="text-purple">대실 추가 시간 요금 (1시간당)</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              심플, 요금제 설정의 대실 사용기간을 설정합니다. 퇴실시간이 대실
              마감시간을 지날 경우, 퇴실 시간은 대실 마감시간이 됩니다. <br />
              (예 : 대실 마감시간 - 18시, 체크인 시간 - 15시, 대실 - 4시간
              사용일 경우 퇴실 시간은 19시가 아닌 18시가 됩니다.)
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오전</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={7000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오후</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={7000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오전</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오후</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">
              대실 추가 인원당 요금 (기본 인원 수 초과시)
            </h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              대실의 추가 인원당 추가 요금을 부과합니다. 기본 인원수를 초과시
              부과되며, 최대 인원수를 초과할 수 없습니다.
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오전</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={5000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>평일 오후</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={5000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오전</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={5000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>주말 오후</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={5000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">장기 (연박) 요금 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              첫 1박은 숙박 요금제가 적용되며, 이후 2박부터 장기(연박) 요금제가
              적용됩니다.
            </p>
            <Checkbox.Group
              onChange={onChangeCheckbox1}
              value={checkedValues1}
              className="flex-row gap-8 mb-8"
            >
              <Checkbox value={1}>프론트 사용 여부</Checkbox>
              <Checkbox value={2}>키오스크 사용 여부</Checkbox>
            </Checkbox.Group>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>월-화</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={80000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>화-수</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={80000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>수-목</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={80000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>목-금</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={80000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>금-토</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={120000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>토-일</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={120000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>일-월</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={80000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
            </div>
          </div>

          <div className="flex-col gap-4 pt-24">
            <h4 className="text-purple">숙박 인원당 추가 요금 설정</h4>
            <p className="font-13 text-gray-500 flex-row flex-items-start mb-8">
              <QuestionCircleFilled
                className="mr-4"
                style={{ width: "14px", height: "14px", marginTop: "2px" }}
              />
              숙박 및 장기(연박) 요금제에 적용되며, 투숙일수 *추가인원*
              추가요금으로 계산됩니다.
            </p>
            <div className="flex-row flex-wrap gap-16">
              <div className="flex-row flex-wrap gap-8">
                <h4>월-화</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>화-수</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>수-목</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>목-금</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>금-토</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={15000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>토-일</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={15000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
              <div className="flex-row flex-wrap gap-8">
                <h4>일-월</h4>
                <InputNumber
                  min={0}
                  max={9999999999}
                  defaultValue={10000}
                  formatter={formatNumber}
                  onChange={onChangeInputNumber}
                  onKeyDown={handleInputNumberKeyDown}
                  onInput={handleInputNumberOnInput}
                  style={{ maxWidth: "11rem" }}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default RoomTypeTabCont;
