import React, { useState } from "react";
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import { Button, ConfigProvider, DatePicker, Table } from "antd";
import { disabledDate, disabledTime } from "../util";

export const CheckRoomHistory = () => {
  // 달력
  const onChangeDatePicker = (date, dateString) => {
    console.log(dateString);
  };

  // 테이블 데이터 예시
  const data = [
    {
      key: "1",
      name: "206",
      roomName: "일반실1",
      statusDate: "2024-07-15",
      statusTime: "15:38:23",
      roomCondition: "통신단절",
    },
    {
      key: "2",
      name: "1006",
      roomName: "일반실",
      statusDate: "2024-10-07",
      statusTime: "18:41:20",
      roomCondition: "객실 클릭",
    },
    {
      key: "3",
      name: "1101",
      roomName: "파티룸",
      statusDate: "2024-10-07",
      statusTime: "18:31:29",
      roomCondition: "객실 클릭",
    },
    {
      key: "4",
      name: "207",
      roomName: "VIP",
      statusDate: "2024-08-01",
      statusTime: "02:52:36",
      roomCondition: "청소완료",
    },
    {
      key: "5",
      name: "201",
      roomName: "일반실",
      statusDate: "2024-07-19",
      statusTime: "12:30:45",
      roomCondition: "퇴실->공실",
    },
  ];

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 2 });

  // 날짜 필터
  const filteredStatusDate = [
    ...new Set(data.map((item) => dayjs(item.statusDate).format("YYYY-MM-DD"))),
  ].map((statusDate) => ({
    text: statusDate,
    value: statusDate,
  }));
  // 시간 필터
  const filteredStatusTime = [
    ...new Set(
      data
        .map((item) => {
          const time = dayjs(item.statusTime, "HH:mm:ss");
          return time.isValid() ? time.format("HH:mm:ss") : null;
        })
        .filter(Boolean)
    ),
  ].map((statusTime) => ({
    text: statusTime,
    value: statusTime,
  }));
  // 객실명 필터
  const filteredName = [...new Set(data.map((item) => item.name))].map(
    (name) => ({
      text: name,
      value: name,
    })
  );
  // 객실유형 필터
  const filteredRoomName = [...new Set(data.map((item) => item.roomName))].map(
    (roomName) => ({
      text: roomName,
      value: roomName,
    })
  );
  // 상태변경내용 필터
  const filteredRoomCondition = [
    ...new Set(data.map((item) => item.roomCondition)),
  ].map((roomCondition) => ({
    text: roomCondition,
    value: roomCondition,
  }));

  // 테이블 컬럼 생성
  const columns = [
    {
      title: "날짜",
      dataIndex: "statusDate",
      key: "statusDate",
      filterSearch: true,
      filters: filteredStatusDate,
      filteredValue: filteredInfo.statusDate || null,
      onFilter: (value, record) =>
        dayjs(record.statusDate).format("YYYY-MM-DD") === value,
      sorter: (a, b) => a.statusDate.localeCompare(b.statusDate),
      sortOrder:
        sortedInfo.columnKey === "statusDate" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "시간",
      dataIndex: "statusTime",
      key: "statusTime",
      filterSearch: true,
      filters: filteredStatusTime,
      filteredValue: filteredInfo.statusTime || null,
      onFilter: (value, record) => {
        const time = dayjs(record.statusTime, "HH:mm:ss");
        return time.isValid() && time.format("HH:mm:ss") === value;
      },
      sorter: (a, b) => a.statusTime.localeCompare(b.statusTime),
      sortOrder:
        sortedInfo.columnKey === "statusTime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => {
        const time = dayjs(text, "HH:mm:ss");
        return time.isValid() ? time.format("HH:mm:ss") : "유효하지 않은 시간";
      },
    },
    {
      title: "객실명",
      dataIndex: "name",
      key: "name",
      filterSearch: true,
      filters: filteredName,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "객실유형",
      dataIndex: "roomName",
      key: "roomName",
      filterSearch: true,
      filters: filteredRoomName,
      filteredValue: filteredInfo.roomName || null,
      onFilter: (value, record) => record.roomName.includes(value),
      sorter: (a, b) => a.roomName.localeCompare(b.roomName),
      sortOrder: sortedInfo.columnKey === "roomName" ? sortedInfo.order : null,
      ellipsis: true,
      responsive: ["sm"],
    },
    {
      title: "상태변경내용",
      dataIndex: "roomCondition",
      key: "roomCondition",
      filterSearch: true,
      filters: filteredRoomCondition,
      filteredValue: filteredInfo.roomCondition || null,
      onFilter: (value, record) => record.roomCondition.includes(value),
      sorter: (a, b) => a.roomCondition.localeCompare(b.roomCondition),
      sortOrder:
        sortedInfo.columnKey === "roomCondition" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  // 필터 초기화 버튼
  const clearFilters = () => {
    setFilteredInfo({});
  };
  // 모두 초기화 버튼
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setPagination({ current: 1, pageSize: 100 });
  };
  // 날짜별 정렬 버튼
  const setStatusDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "statusDate",
    });
  };

  return (
    <div className="p-16 pb-0">
      <h2>객실 상태 변경 이력 조회</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <DatePicker
            showTime
            onChange={onChangeDatePicker}
            defaultValue={dayjs()}
            format="YYYY-MM-DD HH:mm:ss"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          />

          <div className="btn-group ml-auto">
            <Button onClick={setStatusDate}>날짜별 정렬</Button>
            <Button onClick={clearFilters}>전체 필터초기화</Button>
            <Button onClick={clearAll}>모두 초기화</Button>
          </div>
        </div>

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            scroll={{ y: "28.8rem" }}
            className="ant-table-respons"
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
